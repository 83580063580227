import { useNavigate, useParams } from "react-router-dom";
import "./singlePost.css";
import { useEffect, useState } from "react";
import axios from 'axios'
import Topbar from "../topbar/Topbar";
import { Edit } from "@material-ui/icons";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TextField } from "@material-ui/core";

export default function SinglePost() {
  const [categories, setCategories] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const navigate = useNavigate();
  const [data, setData] = useState({
    title: '',
    category: '',
    sub_title: '',
    image: '',
    created_at: '',
    // selectedDate: new Date() // Initialize with the current date
  })
  const { id } = useParams();

  useEffect(() => {
    axios
      .get('https://abcd.ishworineupane.com.np/blog/post/' + id)
      .then(res => {
        console.log(res.data.results[0]);
        setData({
          ...data,
          title: res.data.results[0].title,
          category: res.data.results[0].category,
          sub_title: res.data.results[0].sub_title,
          image: res.data.results[0].image,
          created_at: res.data.results[0].created_at,
        });
        setSelectedDate(new Date(res.data.results[0].created_at));
      })
      .catch(err => console.log(err));
  }, []);
  const handleDateChange = (date) => {
    setData({ ...data, selectedDate: date });
  };

  return (
    <div>
      <Topbar />
      <div className="singlePost">
        <div className="singlePostWrapper">
          <img
            className="singlePostImg postImg" src={`https://abcd.ishworineupane.com.np/images/` + data.image} alt={data.title} />
          <h1 className="singlePostTitle">
            {data.title}
            <div className="singlePostEdit">

              {data.category}
              <Edit />
            </div>
          </h1>

          <div className="singlePostInfo">
           <span>{data.created_at}</span>
          </div>
          <p className="singlePostDesc">


            <p className="postDescription">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.sub_title
                }}></div>
            </p>
          </p>
        </div>
      </div>
    </div>
  );
}
