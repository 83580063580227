import "./settings.css";
import Topbar from "../../components/topbar/Topbar";
import { EmailOutlined, Map, Phone } from "@material-ui/icons";
import logo from './logo.jpg'

export default function Settings() {
  return (
    <div>
      <Topbar />
      <div className="settings">
        <div className="settingsWrapper">
          <div className="settingsPP">
            <img
              src={logo}
              alt=""
            />
          </div>
          <label>Digital:Marketing: Software: Development: Testing: WowDevQA:AI: ArtificialIntelligence: ML: MachineLearning: DL:
             DeepLearningInformationSecurity: CyberSecurity: SecurityTesting: Hacking: QA: QualityAssurance:
             <br/>Top: 15: Software:  <br/>
             1. Online Voting Polling Events Competition <br/>2. Personal Financing Credit Score Builder Analyzer <br/> 3. Multi-Website Advertisement Agent
             <br/> 4. File Security Multi-Level Authentication <br/>5. News Agency Distributor <br/>6. Global Dental <br/>
              7. Hotels Resorts HomeStays Reservation<br/> 8. Restaurants Ordering Serving Delivery<br/> 9. Travels Tours Hiking Trekking Mountaineering
              <br/> 10. Lightweight Healthcare <br/>11. Cyber Security Law rules policy <br/>
               12. Affordable Education for All <br/>13. Stock Trading Prediction Analytics<br/> 14. Ecommerce <br/> 15. AI ML DL ArtificialIntelligence MachineLearning DeepLearning
          </label>
          <br />
          <br />
          <span><EmailOutlined /> wowdevqa@wowdevqa.com</span><br />
          <span><Phone /> +9779801034557</span><br />
          <span><Map /> Imadol, Lalitpur</span>
        </div>
      </div>
    </div>
  );
}
