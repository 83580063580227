import React, { useEffect, useState } from "react";
import { Avatar, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, IconButton, Typography, styled } from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
// styles
import useStyles from "./styles";
import { useLayoutState } from "../../context/LayoutContext";

// components
import PageTitle from "../../components/PageTitle";
// import { Typography } from "../../components/Wrappers";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import axios from "axios";

export default function Dashboard(props) {

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const layoutState = useLayoutState()
  var classes = useStyles();
  const [user, setUser] = useState([]);
  // sessionStorage.getItem("login", "admin_login");

  var id = sessionStorage.getItem('user_id');

  useEffect(() => {
    axios.get("https://abcd.ishworineupane.com.np/dashboard/" + id, {
    }).then(res => setUser(res.data.Result[0]))
      .catch(err => console.log(err));
  }, []);

  return (
    <>
      <Header history={props.history} />
      <Sidebar />
      <div className={classes.dashboardcontent}>

        {/* <PageTitle title="Dashboard" /> */}
        <Card sx={{ maxWidth: 200 }}  style={{ height:"500px", width:"600px"}}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe">

              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
              </IconButton>
            }
            title={user.username} />
          <CardMedia
            component="img"
            style={{ height:"300px", width:"500px"}}
            image={`https://abcd.ishworineupane.com.np/images/` + user.image}
            alt="Paella dish"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Email: {user.email}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Phone: {user.phone}
            </Typography>
          </CardContent>
          {/* <CardActions disableSpacing>
            <IconButton aria-label="add to favorites">
            </IconButton>
            <IconButton aria-label="share">
            </IconButton>
            <ExpandMoreOutlined
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
            </ExpandMoreOutlined>
          </CardActions> */}
          {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>Method:</Typography>
              <Typography paragraph>
                Heat 1/2 cup of the broth in a pot until simmering, add saffron and set
                aside for 10 minutes.
              </Typography>
              <Typography paragraph>
                Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over
                medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring
                occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a
                large plate and set aside, leaving chicken and chorizo in the pan. Add
                pimentón, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook,
                stirring often until thickened and fragrant, about 10 minutes. Add
                saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
              </Typography>
              <Typography paragraph>
                Add rice and stir very gently to distribute. Top with artichokes and
                peppers, and cook without stirring, until most of the liquid is absorbed,
                15 to 18 minutes. Reduce heat to medium-low, add reserved shrimp and
                mussels, tucking them down into the rice, and cook again without
                stirring, until mussels have opened and rice is just tender, 5 to 7
                minutes more. (Discard any mussels that don&apos;t open.)
              </Typography>
              <Typography>
                Set aside off of the heat to let rest for 10 minutes, and then serve.
              </Typography>
            </CardContent>
          </Collapse> */}
        </Card>
      </div>
    </>
  );
}

