import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// components
// pages
import Error from "../pages/error";
import Login from "../pages/login";
// context
import Dashboard from "../pages/dashboard/Dashboard";
import MyCategory from "../pages/dashboard/components/Table/Category";
import UpdateCategory from "../pages/dashboard/components/Table/UpdateCategory";
import Blog from "../pages/dashboard/components/blog/Blog";
import UpdateBlog from "../pages/dashboard/components/blog/UpdateBlog";
import Homepage from "./publicblog/pages/homepage/Homepage";
import SinglePost from "./publicblog/components/singlePost/SinglePost";
import Settings from "./publicblog/pages/homepage/About";
import Search from "./publicblog/components/posts/Search";
import CreateBlog from "../pages/dashboard/components/blog/CreateBlog";
import RecipeReviewCard from "../pages/dashboard/mydash";
import UploadForm from "./Upload";
import FileList from "./Filelist";

export default function App() {
  // global
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        {/* <Route path="/upload" element={<UploadForm />} /> */}
        {/* <Route path="/files" element={<FileList />} /> */}
        <Route path="/search/:q" element={<Search />} />
        <Route exact path="/admin/dashboard" element={<Dashboard />} />
        <Route exact path="/post/:id" element={<SinglePost />} />
        <Route exact path="about" element={<Settings />} />
        <Route exact path="/admin/login" element={<Login />} />
        <Route exact path="/admin/categories" element={<MyCategory />} />
        <Route exact path="/admin/categories/edit/:id" element={<UpdateCategory />} />
        <Route exact path="/admin/blog/edit/:id" element={<UpdateBlog />} />
        <Route exact path="/admin/blog/create" element={<CreateBlog />} />
        <Route exact path="/admin/dashboar" element={<RecipeReviewCard />} />
        <Route exact path="/admin/blogs" element={<Blog />} />
        <Route path="*" element={<Error />} />

      </Routes>
    </BrowserRouter>
  );
}
