import React, { useEffect, useState } from 'react';
import { Table, TableRow, TableHead, TableBody, TableCell, TableContainer, Paper, Button } from "@material-ui/core";
// styles
import axios from "axios";
import { Link,  useNavigate } from 'react-router-dom';
import Header from '../../../../components/Header/Header';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import useStyles from '../../styles';

export default function Blog() {
    var classes = useStyles();
    const [mydata, setMyData] = useState([]);
    const [files, setFiles] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        axios.get('https://abcd.ishworineupane.com.np/blogs')
            .then(res => {
                if (res.data.Status === "Success") {
                    console.log(res.data)
                    setMyData(res.data.results);
                } else {
                    console.log("Error")
                }
            })
            .catch(err => console.log(err));
    }, []);

    const handleDelete = (id) => {
        axios.get('https://abcd.ishworineupane.com.np/blog/delete/' + id)
            .then(res => {
                if (res.data.Status === "Success") {
                    window.location.reload(true);
                } else {
                    console.log("Error")
                }
            })
            .catch(err => console.log(err));
    }

    return (
        <>
            <Header />
            <Sidebar /><br />

            <div className={classes.dashboardcontent}>
                <div style={{ textAlign: "right", marginTop: "2rem", alignItems: "right", display: "flex", justifyContent: "right" }}>
                <Link to={`/admin/blog/create`} className='btn btn-primary btn-sm me-2'><Button variant="contained" >Create Blog</Button></Link>
                </div>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="right">Title</TableCell>
                                <TableCell align="right">Categories</TableCell>
                                <TableCell align="right">SubTitle</TableCell>
                                <TableCell align="right">Image</TableCell>
                                <TableCell align="right">Action&nbsp;(g)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mydata.map((blog, id) => (
                                <TableRow
                                    key={id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="category">
                                        {blog.id}
                                    </TableCell>
                                    <TableCell align="right">{blog.title}</TableCell>
                                    <TableCell align="right">{blog.category}</TableCell>
                                    <TableCell align="right"><div className={classes.para}
                                        dangerouslySetInnerHTML={{
                                            __html: blog.sub_title
                                        }}></div> 
                                        
                                        </TableCell>

                                    <TableCell align="right">{
                                        <img src={`https://abcd.ishworineupane.com.np/images/` + blog.image} style={{ height: "100px", width: "100px" }} alt={blog.title} className='employee_image' />
                                    }</TableCell>

                                    <TableCell align="right">
                                        <Button variant="contained" onClick={() => handleDelete(blog.id)}>Delete</Button>
                                        <Link to={`/admin/blog/edit/` + blog.id} className='btn btn-primary btn-sm me-2'><Button variant="contained" >Update</Button></Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />

                <br />
                <br />
                <br />
            </div>
        </>

    );
}