import React, { useEffect, useState } from "react";
import {Grid,CircularProgress,Typography,Button,Tabs,Tab,TextField,Fade} from "@material-ui/core";
import { Navigate  } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.jpg";

// context
import axios from "axios";

function Login(props) {
  var classes = useStyles();

  var [isLoading, setIsLoading] = useState(false);
  var [activeTabId, setActiveTabId] = useState(0);
  var [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("")
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    console.log("first")
    event.preventDefault();
    axios.post('https://abcd.ishworineupane.com.np/login', { email, password })
      .then(res => {
        console.log(res)
        if (res.data.message === true) {
          sessionStorage.setItem("user_id", res.data.user_id);
          // console.log(res.data.user_id + "..........")
          sessionStorage.setItem("login", "admin_login");
          if(res.data.user_id){
          navigate("/admin/dashboard");
          }
          else{
          navigate("/");

          }
          <Navigate to="/admin/dashboard"/>   
        } else {
          setError(res.data.alert);
        }
      })
      .catch(err => console.log(err));
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>WOW DEV QA</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />

          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                Good Morning
              </Typography>


              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={email}
                onChange={e => setEmail(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={password}
                onChange={e => setPassword(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                 
                  <Button
                  disabled={
                    password.length === 0 
                  }
                  onClick= {handleSubmit}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Login
                </Button>
                )}

              </div>
            </React.Fragment>
          )}

        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2023-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://flatlogic.com" rel="noopener noreferrer" target="_blank"></a> All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default (Login);
